var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154"
      },
      [
        _c("header", [
          this.new
            ? _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.maps.addresortmap")))
                ]),
                _c("p", { staticClass: "mb-0 text-base" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.maps.addresortmapdescr")))
                ])
              ])
            : _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.maps.editresortmap")))
                ]),
                _c("p", { staticClass: "mb-0 text-base" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.maps.editresortmapdescr")))
                ])
              ])
        ]),
        _c(
          "div",
          [
            _c("LabelForm", [_vm._v(_vm._s(_vm.$t("resorts.maps.type")))]),
            _c(
              "vs-select",
              {
                staticClass: "w-full border-grey-light md:w-1/2 lg:w-1/2",
                on: { change: _vm.changeType },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              _vm._l(_vm.mapTypes, function(type, index) {
                return _c("vs-select-item", {
                  key: index,
                  attrs: { value: type.id, text: type.key }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm.selectedCustom
          ? _c(
              "div",
              [
                _c("LabelForm", [_vm._v(_vm._s(_vm.$t("resorts.maps.key")))]),
                _c("vs-input", {
                  staticClass: "input-modal w-full border-grey-light",
                  attrs: { type: "text", maxlength: "50" },
                  model: {
                    value: _vm.custom_key,
                    callback: function($$v) {
                      _vm.custom_key = $$v
                    },
                    expression: "custom_key"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("resorts.maps.imageupload")))
            ]),
            _c("ImageForm", {
              attrs: {
                id: "mapImage",
                validationWeight: "8192",
                validationMessage: this.$t("resorts.maps.erroruploading"),
                width: "200",
                height: "200",
                src: _vm.image,
                name: "map-image"
              },
              on: { updateImage: _vm.handlerUpdateMapImage }
            })
          ],
          1
        ),
        _c(
          "b-alert",
          {
            staticClass: "my-5 rounded-lg",
            attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
          },
          [
            _c(
              "ul",
              { staticClass: "list-none" },
              _vm._l(_vm.errorsOnSave, function(errorString, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "mt-12" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  color: "primary",
                  type: "filled rounded-lg w-48 mr-10"
                },
                on: {
                  click: function($event) {
                    return _vm.saveMap()
                  }
                }
              },
              [
                this.new
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("resorts.maps.save")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("resorts.maps.update")))])
              ]
            ),
            _c(
              "vs-button",
              {
                staticClass: "w-48 rounded-lg cancel",
                attrs: { type: "cancel" },
                on: {
                  click: function($event) {
                    return _vm.$router.push(
                      "/resorts/" + _vm.resortUuid + "/maps"
                    )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("resorts.maps.cancel")) + " ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }