<template>
    <div>
        <div class="w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154">
            <header>
                <div v-if="this.new">
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.maps.addresortmap") }}</h3>
                    <p class="mb-0 text-base">{{ $t("resorts.maps.addresortmapdescr") }}</p>
                </div>
                <div v-else>
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.maps.editresortmap") }}</h3>
                    <p class="mb-0 text-base">{{ $t("resorts.maps.editresortmapdescr") }}</p>
                </div>
            </header>

            <div>
                <LabelForm>{{ $t("resorts.maps.type") }}</LabelForm>
                <vs-select class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="type" @change="changeType">
                    <vs-select-item v-for="(type, index) in mapTypes" :key="index" :value="type.id" :text="type.key" />
                </vs-select>
            </div>
            <div v-if="selectedCustom">
                <LabelForm>{{ $t("resorts.maps.key") }}</LabelForm>
                <vs-input type="text" maxlength="50" class="input-modal w-full border-grey-light" v-model="custom_key" />
            </div>

            <div>
                <LabelForm>{{ $t("resorts.maps.imageupload") }}</LabelForm>
                <ImageForm
                    id="mapImage"
                    validationWeight="8192"
                    :validationMessage="this.$t('resorts.maps.erroruploading')"
                    width="200"
                    height="200"
                    :src="image"
                    @updateImage="handlerUpdateMapImage"
                    name="map-image" />
            </div>

            <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
                <ul class="list-none">
                    <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
                </ul>
            </b-alert>

            <div class="mt-12">
                <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveMap()">
                    <span v-if="this.new">{{ $t("resorts.maps.save") }}</span>
                    <span v-else>{{ $t("resorts.maps.update") }}</span>
                </vs-button>
                <vs-button type="cancel" class="w-48 rounded-lg cancel" @click="$router.push(`/resorts/${resortUuid}/maps`)">
                    {{ $t("resorts.maps.cancel") }}
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>

import MapService from '@/modules/Resorts/Services/MapService'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import ImageForm from '@/modules/Shared/Components/form/ImageForm'
import localStorageHelper from '@/modules/Shared/Helpers/localStorageHelper'
import store from "@/modules/Shared/Store/store";

export default {
    name: 'map-form',
    components: {
        LabelForm,
        ImageForm
    },
    props: {
        mapUuid: String,
        map: {
            type: Object,
            default: () => {}
        },
        mapTypesCreated: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            resortUuid: '',
            errorsOnSave:[],

            type: null,
            image: null,
            imageToUpload: null,
            mapTypes: [],
            typesCreated: [],
            selectedCustom: false,
            custom_key: null
        }
    },
    async beforeRouteEnter (to, from, next) {
        const map = to.params.map
        await store.dispatch('setRouteElement', map)
        next()
    },
    async created() {
        const mapEdited = localStorageHelper.set('map', this.map)

        this.typesCreated = this.mapTypesCreated
        this.resortUuid = this.$route.params.uuid
        this.mapTypes = await MapService.getMapTypes(this.resortUuid)

        if (!this.new) {
            this.custom_key = mapEdited.custom_key
            this.type = mapEdited.id_map_key
            this.image = mapEdited.full_map_file
            this.typesCreated = this.mapTypesCreated.filter( (type) => type !== this.type )
        }
    },
    methods: {
        handlerUpdateMapImage(file) {
            this.imageToUpload = file
            this.image = file ? URL.createObjectURL(file) : null
        },
        changeType(type) {
            this.selectedCustom = type === 7
        },
        async saveMap() {
            const payload = new FormData()
            if (this.type) {
                payload.append('type', this.type)
            }

            if (this.imageToUpload) {
                payload.append('file', this.imageToUpload)
            }

            if(this.selectedCustom) {
                payload.append('custom_key', this.custom_key)
            }

            if (!this.hasErrors()) {
                let response

                try {
                    this.$vs.loading()
                    if (this.map && !this.new) {
                        response = await MapService.saveMap(this.resortUuid, this.mapUuid, payload)
                    } else {
                        response = await MapService.createMap(this.resortUuid, payload)
                    }

                    if (response.code == 200) {
                        const text = this.new ? this.$t("resorts.maps.createdok") : this.$t("resorts.maps.updatedok")
                        this.$vs.notify( { text: text, color: 'success',position:'top-right' } )
                        setTimeout(() => {
                            this.$vs.loading.close()
                            this.$router.push(`/resorts/${this.resortUuid}/maps`);
                        }, 1000)
                    }
                } catch(error) {
                    this.$vs.notify( { text: this.new ? this.$t("resorts.maps.createdko") : this.$t("resorts.maps.updatedko"), color: 'danger',position:'top-right'})
                } finally {
                    this.$vs.loading.close()
                }
            }
        },

        hasErrors() {
            this.errorsOnSave = []

            if (!this.type) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.maps.typeempty")]
            }

            if (!this.image) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.maps.imageempty")]
            }

            if (this.type && this.typesCreated.includes(this.type)) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.maps.mapexists")]
            }

            return this.errorsOnSave.length !== 0;
        }
    },
    computed: {
        new() {
            const map = localStorageHelper.get('map')
            return JSON.stringify(map) === JSON.stringify({}) || map == undefined || !map
        }
    },
    destroyed() {
        localStorageHelper.remove('map')
    }
}
</script>
